const theme = {
  fonts: {
    body: 'system-ui, sans-serif',
  },
  colors: {
    text: '#fff',
    background: '#111',
  },
};

export default theme;
